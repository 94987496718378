import { SERVER_HOST, handleResponse } from '@api';
import { TAddress } from '@components/types';
import axios from 'axios';

export async function getVisitor(
  visitor_id: string
) {
  const response = await axios.get(`${SERVER_HOST}/api/v1/visitors/${visitor_id}`);
  return handleResponse(response);
}

export async function createUser(
  full_name: string,
  phone: string,
) {
  const response = await axios.post(`${SERVER_HOST}/api/v1/users/create`, { full_name, phone }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return handleResponse(response);
}

export async function createAddress(
  address: TAddress
) {
  const response = await axios.post(`${SERVER_HOST}/api/v1/addresses/create`, address, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return handleResponse(response);
}

export async function getUserLocation(
  longitude: number,
  latitude: number
) {
  const response = await axios.get(`${SERVER_HOST}/api/v1/addresses/detect?latitude=${latitude}&longitude=${longitude}`);
  return handleResponse(response);
}
