import React, { useState } from 'react';
import { Select, notification } from 'antd';
import { TGeoLocation, TOption } from '@components/types';
import { getLocation } from '@core/geolocation';
import { AiOutlineClose } from 'react-icons/ai';
import { MdMyLocation } from 'react-icons/md';
import { getAutoSuggest } from '@api/autosuggest';
import Cookies from 'js-cookie';
import { getUserLocation } from '@api/visitors';
import { FiLoader } from 'react-icons/fi';

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Location: React.FC<Props> = ({ showModal, setShowModal }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [locationCoords, setLocationCoords] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const checkStale = (location: TGeoLocation) => {
    return Date.now() - location.last_updated > 60000;
  };

  const fetchUserLocation = () => {
    if (!navigator.geolocation) {
      api.error({
        message: "Geolocation isn't supported by your browser",
        placement: 'bottomRight',
      });
    }

    setIsLoading(true);
    getLocation()
      .then((location) => {
        if (checkStale(location)) {
          api.error({
            message: 'Please enable location access to use this feature.',
            placement: 'bottomRight',
          });
        } else {
          getUserLocation(location?.longitude, location?.latitude).then(
            (currentLocation) => {
              if (currentLocation?.city?.name && currentLocation?.state?.name) {
                const myLocation = {
                  Location: {
                    name: `${currentLocation?.city?.name}, ${currentLocation?.state?.name}`,
                  },
                };
                localStorage.setItem('location', JSON.stringify(myLocation));
                Cookies.set('location', JSON.stringify(myLocation));
              }

              api.success({
                message: 'Location saved',
                placement: 'bottomRight',
              });
            }
          );
        }
      })
      .catch(() => {
        api.error({
          message: 'Oops! something went wrong.',
          placement: 'bottomRight',
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowModal(false);
      });
  };

  const handleSearch = (value: string) => {
    let q = value;
    let intent = 'citystate';
    getAutoSuggest(q, intent).then((result) => {
      setSearchResult(result);
    });
  };

  const addLocation = (value: string, option: TOption) => {
    if (value !== '') {
      localStorage.setItem('location', value);
      Cookies.set('location', value); // Adjust the expiration as needed (in days)
      setShowModal(false);
    }
  };

  return (
    <div>
      {contextHolder}
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-10 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div
              className="md:max-w-[620px] md:min-w-[420px] relative my-6 mx-auto"
              onClick={(e) => e.stopPropagation()}
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-neutral-50 outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between px-4 py-4 gap-2  rounded-t ">
                  <h3 className="text-md  font-semibold text-neutral-700">
                    Please provide your location
                  </h3>
                  <span
                    className="cursor-pointer lt-sm:pt-1"
                    onClick={() => setShowModal(false)}
                  >
                    <AiOutlineClose />
                  </span>
                </div>

                {/*body*/}
                <div className="relative py-2 px-4 flex-auto">
                  <Select
                    showSearch
                    onSearch={handleSearch}
                    style={{ width: '100%' }}
                    placeholder="Enter Your City"
                    onSelect={addLocation}
                    options={
                      searchResult.map((item) => ({
                        value: JSON.stringify({
                          Location: item,
                          Coords: locationCoords,
                        }),
                        label: `${item.name}`,
                      })) as TOption[]
                    }
                  />
                </div>

                <div className="p-4">
                  <button
                    type="button"
                    onClick={fetchUserLocation}
                    className="w-full h-8 text-brand_indigo hover:text-white flex justify-center items-center gap-2 transition transition-all duration-200 cursor-pointer rounded border border-solid border-brand_indigo hover:bg-brand_indigo"
                  >
                    {isLoading ? (
                      <>
                        <FiLoader className="w-4 h-4 animate-spin" />
                        <span className="text-sm font-manrope font-semibold">
                          Loading...
                        </span>
                      </>
                    ) : (
                      <>
                        <MdMyLocation className="w-4 h-4" />
                        <span className="text-sm font-manrope font-semibold">
                          Get Current Location
                        </span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default Location;
