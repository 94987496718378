import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { sanityIoImageLoader, staticImageLoader } from "@core/utils";
import { useState } from "react";

interface Props {
  homeLink?: string;
}

export const BackBar: React.FC<Props> = ({ homeLink }) => {
  const [ratio, setRatio] = useState(16/9);
  const router = useRouter();
  return (
    <div className="flex bg-neutral-50 justify-between items-center sticky lt-sm:relative top-0 z-40 h-10 p-2 font-manrope">
      <div className="flex justify-start" >
        <Link href={homeLink || "/"} >
          <Image
            priority={true}
            loader={staticImageLoader}
            src="public/jhattse-logo.svg"
            width={130}
            height={80}
            alt="Jhattse logo"
            onLoadingComplete={({ naturalWidth, naturalHeight }) => 
                setRatio(naturalWidth / naturalHeight)
            }
          />
        </Link>
      </div>
      <div className="flex justify-end mx-1 select-none">
        <span
          className="grid grid-flow-col gap-1 text-lg font-semibold cursor-pointer"
          onClick={() => {router.back();}}
        >
          <p className="text-sky-600 lt-sm:hidden">Back</p>
          <p className="text-sky-600 mt-1">
            <MdOutlineKeyboardBackspace className="lt-sm:text-md" />
          </p>
        </span>
      </div>
    </div>
  );
};
